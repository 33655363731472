import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["inputTag"]
  static values = { url: String }

  async fetchBackendData(e) {
    const value = e.target.value

    if (value && this.urlValue) {
      const url = this.urlValue + "?query=" + value
      let response = await fetch(url);
      let newTags = await response.json();

      let tagify = this.inputTagTarget.tagify
  
      tagify.settings.whitelist = newTags;
    }
  }
}