import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  toggleReadonly(e) {
    const checked = e.target.checked
    console.log(checked)
    this.inputTarget.readOnly = checked
  }
}