import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  copyValue(e) {
    e.preventDefault()

    const id = e.currentTarget.dataset.elementId
    const el = document.getElementById(id)
    if (el) {
      if (navigator.clipboard) {
        el.select()
        navigator.clipboard.writeText(el.value)
      } else {
        navigator.permissions
          .query({ name: 'clipboard-write' })
          .then((result) => {
            if (result.state == 'granted' || result.state == 'prompt') {
              el.select()
              navigator.clipboard.writeText(el.value)
            }
          })
          .catch((error) => {
            console.log(error)
            alert("Ihr Browser unterstützt keinen Zugriff auf die Zwischenablage")
          })
      }
    }
  }
}
