import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "colorPreview" ]

  changeColorPreview(e) {
    const color = e.target.value
    const hexRegex = /^#?([0-9a-fA-F]{6})$/
    if (hexRegex.test(color)) {
      this.colorPreviewTarget.style.backgroundColor = color
    } else {
      this.colorPreviewTarget.style.backgroundColor = 'white'
    }
  }
}
